#termDescription ol {
    margin: 0;
    padding: 0;
    counter-reset: ol0;
    list-style-type: none;
    li {
        margin-left: 1.5em;
        &:before {
            counter-reset: ol1;
            counter-increment: ol0;
            content: counter(ol0,lower-alpha) ". ";
        }
        li {
            margin-left: 1.5em;
            &:before {
                counter-reset: ol2;
                counter-increment: ol1;
                content: counter(ol1,lower-roman) ". ";
            }
            li {
                margin-left: 1.5em;
                &:before {
                    counter-reset: ol3;
                    counter-increment: ol2;
                    width: 50px;
                    content: counter(ol1,lower-roman) "." counter(ol2,lower-roman) ". ";
                }
                li {
                    margin-left: 1.5em;
                    &:before {
                        counter-reset: ol4;
                        counter-increment: ol3;
                        width: 55px;
                        content: counter(ol1,lower-roman) "." counter(ol2,lower-roman) "." counter(ol3,lower-roman) ". ";
                    }
                    li {
                        margin-left: 1.5em;
                        &:before {
                            counter-reset: ol5;
                            counter-increment: ol4;
                            width: 60px;
                            content: "• ";
                        }
                    }
                }
            }
        }
    }
  }
  #termDescription ul {
    li {
        list-style-type: disc;
        li {
            list-style-type: circle;
        }
    }
  }
  .termTitle {
    flex-grow: 1;
    margin-left: 15px;
}