.shimmer-shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #e7eaf3 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: '800px 104px';
  display: inline-block;
  position: relative;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
