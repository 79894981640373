#root {
  min-height: 100vh;
}

h5 {
  font-size: 16px;
  line-height: 1.2;
}

.react-datepicker__input-container {
  width: 100%;
}

h6 {
  font-size: 14px;
  line-height: 1.2;
}

html {
  * {
    font-family: 'GT Walsheim', Helvetica, Arial, sans-serif;
    max-width: 100%;
  }
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: lighter;
  font-weight: 100;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim.otf');
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim-Bold.ttf');
  font-display: swap;
}

.no-hover {
  &:hover {
    background-color: transparent !important;
  }
}

.addressCard > .addressSection:nth-last-child(3) + div {
  border: none;
}

.custom-terms {
  h6 {
    font-family: 'GT Walsheim';
    font-weight: 300;
    font-size: 16px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Override phone input */
.PhoneInput {
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  padding-left: 16px;
  padding-top: 20px;
  margin-top: 20px;
  // &:hover {
  //   border-bottom: 2px solid #1f0d33;
  // }
  // &:focus {
  //   border-bottom: 2px solid #1f0d33;
  // }
  select {
    font-size: 14px;
    option {
      padding: 5px;
    }
  }
  .PhoneInputInput {
    font-size: 16px;
    padding: 10px;
    font-family: 'GT Walsheim';
    font-weight: 500;
    border: 0;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      font-family: 'GT Walsheim';
      font-weight: 300;
      color: #000000;
    }

    &:-ms-input-placeholder {
      font-family: 'GT Walsheim';
      font-weight: 300;
      color: #000000;
    }

    &::-ms-input-placeholder {
      font-family: 'GT Walsheim';
      font-weight: 300;
      color: #000000;
    }
  }
  .PhoneInputCountrySelectArrow {
    border: 1px solid;
    border-top: 0;
    border-left: 0;
  }
}
.phoneInputError {
  font-size: 12px;
  color: #f44336;
  font-weight: bold;
}
.item-render {
  margin-top: 10px;
  margin-bottom: 10px;
}

.item-render-fileupload {
  margin-top: 10px;
  margin-bottom: 20px;
}

html {
  background-color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiPopover-root {
  z-index: 999999 !important;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  display: none;
}
